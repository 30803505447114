import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  connect() {
    this.updateWindowSize();
  }

  updateWindowSize() {
    document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
    document.documentElement.style.setProperty('--window-inner-width', `${window.innerWidth}px`);
  }
}
